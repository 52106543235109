
import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { AreaUpdateRequest, ClientModule, Location, MyHttpApi, Printer, UuidName } from 'utils/api';
import { clientIdToTypeAndName, getSelectedClient, privilegeItemFromElement } from 'utils/corporation-util';

@autoinject
export class PosAreaEdit {
  private area: AreaUpdateRequest = {
    locationId: "",
    name: "",
    requestPersonCountForNewTable: false,
    signOutAfterSave: false,
    closeBillsManually: false,
    hidePersonChoice: false,
    eanInput: false,
    doNotPrintReceipts: false,
    lastReceiptInFrontPage: false,
    lastReceiptAskForEmail: false,
    freeSaleAllowed: false,
    tableReservationDefaults: [],
    type: "CLIENT",
    delete: false,
    defaultView: "POS",
    terminalStatusListOrientation: "HORIZONTAL",
    viewChangeDisallowed: false,
    hideTerminalStatusList: false,
    disallowPosUseDuringTransaction: false,
  };
  private clientId?: number = undefined;
  private typeAndName = "";
  private locationList: Location[] = [];
  private printerList: Printer[] = [];
  private selectedPaymentTerminalClients: UuidName[] = [];
  private reservation?: number = undefined;
  private foodOrderModule?: ClientModule = undefined;

  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N) {
  }

  async activate(params: { id?: string; clientId?: number }) {
    if (params.id) {
      let area = await this.api.areaByIdWithPaymentTerminals({ id: params.id });
      this.area = {
        ...area.area,
        delete: !!area.area.deleteTime,
        type: 'CLIENT',
      };
      this.selectedPaymentTerminalClients = area.paymentTerminalClientIds;
      this.clientId = privilegeItemFromElement(area.area)?.id;
    }
    if (!params.id) {
      this.clientId = getSelectedClient() || params.clientId;
    }

    this.typeAndName = await clientIdToTypeAndName(this.api, this.i18n, this.clientId);

    [this.locationList, this.printerList, this.foodOrderModule] = await Promise.all([
      this.api.locationList({ id: this.clientId }),
      this.api.printerList({ id: this.clientId }),
      this.api.clientActiveModuleByClientIdAndType({ clientId: this.clientId || 0, type: "FoodOrder" }),
    ]);
  }

  async save(deleted: boolean) {
    if (!this.clientId) {
      return;
    }
    await this.api.areaUpdate({
      ...this.area,
      privilegeId: this.clientId,
      type: 'CLIENT',
      delete: deleted,
    });
    this.router.navigateBack();
  }

  addReservationTime() {
    if (this.reservation) {
      // clean the value
      const hours = Math.trunc(this.reservation);
      const quarters = Math.round((this.reservation - hours) * 4);
      const minutes = (quarters - (quarters % 0.25)) / 4;
      const reservation = hours + minutes;
      if (!this.area.tableReservationDefaults.includes(reservation)) {
        this.area.tableReservationDefaults.push(hours + minutes);
      }
      this.reservation = undefined;
    }
  }

  removeReservation(reservation: number) {
    this.area.tableReservationDefaults = this.area.tableReservationDefaults.filter(x => x !== reservation);
  }

  // * Not enum because of order is wanted to be this
	@computedFrom('foodOrderModule')
	get areaDefaultViewList() {
		if (this.foodOrderModule) {
			return [
				{ id: "POS", name: this.i18n.tr("AreaDefaultView.POS", {}) },
				{ id: "FOOD_ORDER", name: this.i18n.tr("AreaDefaultView.FOOD_ORDER", {}) },
				{ id: "FLOOR_PLAN_POS", name: this.i18n.tr("AreaDefaultView.FLOOR_PLAN_POS", {}) },
				{ id: "FLOOR_PLAN_FOOD_ORDER", name: this.i18n.tr("AreaDefaultView.FLOOR_PLAN_FOOD_ORDER", {}) },
			];
		}
		return [
			{ id: "POS", name: this.i18n.tr("AreaDefaultView.POS", {}) },
			{ id: "FLOOR_PLAN_POS", name: this.i18n.tr("AreaDefaultView.FLOOR_PLAN_POS", {}) },
		];
	}
}
