import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { HeralesManifestRow, HeralesManifestRowProduct, HeralesParty, MasterProductMatch, MasterProductStorageUnit, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';

@autoinject
export class ManifestsProductLink {
  private manifestId = "";
  private row?: HeralesManifestRow;
  private rowProduct?: HeralesManifestRowProduct;
  private supplier?: HeralesParty;
  private product?: MasterProductMatch;

  private associateProductList: string[] = [];
  private associateProductSupplierCode = "";
  private associateProductSupplierCodeStorageUnitList: MasterProductStorageUnit[] = [];
  private associateProductSupplierCodeStorageUnitId?: number;
  
  private associateProductEan = "";
  private associateProductEanName = "";
  private associateProductEanStorageUnitList: MasterProductStorageUnit[] = [];
  private associateProductEanStorageUnitId?: number;

  constructor(private router: Router, private api: MyHttpApi, private notify: Notify) {
  }

  async activate(params: { manifestId: string, id: string }) {
    this.manifestId = params.manifestId;

    let data = await this.api.heralesAdminManifestById({ id: params.manifestId });
    const rowId = parseInt(params.id);
    this.row = data.row.find(p => p.id === rowId);
    this.rowProduct = data.rowProduct.find(p => p.heralesManifestRowId == rowId);
    this.supplier = data.party.find(p => p.partyType == 'SUPPLIER');

    if (this.rowProduct) {
      if (this.rowProduct.eanId) {
        this.product = await this.api.masterProductStorageUnitEanById({ id: this.rowProduct.eanId });
      } else if (this.rowProduct.storageUnitId) {
        this.product = await this.api.masterProductStorageUnitById({ id: this.rowProduct.storageUnitId });
      } else {
        /* shouldn't happen */
      }
    }

    let productList = await this.api.masterProductList();
    this.associateProductList = productList.map(p => p.id + " " + p.name);

    if (this.supplier && this.row?.productCode) {
      const link = await this.api.heralesAdminMasterProductStorageUnitBySupplierCode({
        partyId: this.supplier.partyId,
        productCode: this.row.productCode
      });
      if (link) {
        this.associateProductSupplierCode = this.associateProductList.find(p => p.startsWith(link.masterProductId + " ")) || "";
        await this.associateProductSupplierCodeChoose(link.masterProductId + " ");
        this.associateProductSupplierCodeStorageUnitId = link.id;
      }
    }

    if (this.row?.ean) {
      let link = await this.api.heralesAdminMasterProductStorageUnitEanByEan({ ean: this.row.ean });
      if (link) {
        let masterProduct = await this.api.masterProductStorageUnitEanById({ id: link.id });
        this.associateProductEan = this.associateProductList.find(p => p.startsWith(masterProduct.product.id + " ")) || "";
        this.associateProductEanName = link.name;
        await this.associateProductEanChoose(masterProduct.product.id + " ");
        this.associateProductEanStorageUnitId = masterProduct.storageUnit.id;
      } else {
        /* prefill associateProductEanName if it's empty */
        this.associateProductEanName ||= this.row?.productName || '';
      }
    }
  }

  async associateProductSupplierCodeChoose(value: string) {
    let masterProductId = parseInt(value.replace(/ .*$/, ""));
    if (masterProductId) {
      let masterProduct = await this.api.masterProductById({ id: masterProductId });
      this.associateProductSupplierCodeStorageUnitList = masterProduct.storageUnits.map(p => p.storageUnit);
    } else {
      this.associateProductSupplierCodeStorageUnitList = [];
      this.associateProductSupplierCodeStorageUnitId = undefined;
    }
  }

  async createSupplierCodeLink() {
    if (!this.supplier || !this.row) {
      return;
    }
    await this.api.heralesAdminSupplierProductLink({
      partyId: this.supplier.partyId,
      productCode: this.row.productCode,
      storageUnitId: this.associateProductSupplierCodeStorageUnitId,
    });
    this.notify.info("manifest.savedSupplierCode");
    this.router.navigateBack();
  }

  async associateProductEanChoose(value: string) {
    let masterProductId = parseInt(value.replace(/ .*$/, ""));
    if (masterProductId) {
      let masterProduct = await this.api.masterProductById({ id: masterProductId });
      this.associateProductEanStorageUnitList = masterProduct.storageUnits.map(p => p.storageUnit);
    } else {
      this.associateProductEanStorageUnitList = [];
    }
    this.associateProductEanStorageUnitId = this.associateProductEanStorageUnitList[0]?.id;
  }

  async createEanLink() {
    if (!this.row?.ean) {
      return;
    }
    await this.api.heralesAdminEanLink({
      ean: this.row.ean,
      storageUnitId: this.associateProductEanStorageUnitId,
      name: this.associateProductEanName,
    });
    this.notify.info("manifest.savedEan");
    this.router.navigateBack();
  }
}
