import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { HeralesManifest, HeralesManifestRow, HeralesParty, MyHttpApi } from 'utils/api';
interface UIHeralesManifestRow extends HeralesManifestRow {
  manifestId: string;
  status: string;
}
@autoinject
export class ManifestsEdit {
  manifest?: HeralesManifest;
  party: HeralesParty[] = [];
  row: UIHeralesManifestRow[] = [];

  partyFieldList: FieldSpec[] = [
    { key: "partyType", header: "manifest.partyType", type: "enum", enum: "PartyType" },
    { key: "partyId", header: "manifest.partyId", type: "text", },
    { key: "name", header: "manifest.name", type: "text" },
    { key: "businessId", header: "manifest.businessId", type: "text" },
    { key: "addressName", header: "manifest.addressName", type: "text" },
    { key: "addressName2", header: "manifest.addressName2", type: "text" },
    { key: "addressStreet", header: "manifest.addressStreet", type: "text" },
    { key: "addressZip", header: "manifest.addressZip", type: "text" },
    { key: "addressCity", header: "manifest.addressCity", type: "text" },
    { key: "addressCountry", header: "manifest.addressCountry", type: "text" },
  ];

  rowFieldList: FieldSpec[] = [
    { key: "status", header: "manifest.status", type: "text", },
    { key: "ean", header: "EAN", type: "text" },
    { key: "productCode", header: "manifest.productCode", type: "text" },
    { key: "productName", header: "manifest.productName", type: "text" },
    { key: "baseUnit", header: "manifest.baseUnit", type: "enum", enum: "BaseUnit" },
    { key: "storageUnit", header: "manifest.storageUnit", type: "number" },
    { key: "orderedStorageUnitQuantity", header: "manifest.orderedStorageUnitQuantity", type: "number" },
    { key: "deliveredStorageUnitQuantity", header: "manifest.deliveredStorageUnitQuantity", type: "number" },
    { key: "priceExcludingVat", header: "manifest.priceExcludingVat", type: "number", minimumFractionDigits: 2 },
    { key: "priceIncludingVat", header: "manifest.priceIncludingVat", type: "number", minimumFractionDigits: 2 },
    { key: "vatPercent", header: "manifest.vatPercent", type: "number", },
    { key: "totalExcludingVat", header: "manifest.totalExcludingVat", type: "number", minimumFractionDigits: 2 },
    { key: "totalIncludingVat", header: "manifest.totalIncludingVat", type: "number", minimumFractionDigits: 2 },
  ];

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate(params: { id: string }) {
    let { manifest, party, row, rowProduct } = await this.api.heralesAdminManifestById({ id: params.id });
    let matchedRows = new Set<number>();
    for (let x of rowProduct) {
      matchedRows.add(x.heralesManifestRowId);
    }
    this.manifest = manifest;
    this.party = party;
    this.row = row.map(x => ({ ...x, manifestId: params.id, status: matchedRows.has(x.id) ? "OK" : "⋯" }));

    /* place unmatched rows to the top */
    this.row.sort((a, b) => {
      if (!matchedRows.has(a.id)) {
        return -1;
      } else if (!matchedRows.has(b.id)) {
        return 1;
      } else {
        return 0;
      }
    })
  }

  async delete() {
    if (!this.manifest) {
      return;
    }
    await this.api.heralesAdminManifestDeleteById({ id: this.manifest.id })
    this.router.navigateToRoute("manifests/list");
  }

  async undelete() {
    if (!this.manifest) {
      return;
    }
    await this.api.heralesAdminManifestUndeleteById({ id: this.manifest.id })
    this.manifest.deleteTime = undefined;
    this.manifest.deleteActorId = undefined;
  }

  rowCallParty(key: string, row: HeralesParty) {
    if (row.partyId && row.partyType) {
      this.router.navigateToRoute("manifests/party-link", {
        partyId: row.partyId,
        partyType: row.partyType,
      });
    }
  }

  rowCall(key: string, row: UIHeralesManifestRow) {
    if (row.manifestId && row.id) {
      this.router.navigateToRoute("manifests/product-link", {
        id: row.id,
        manifestId: row.manifestId,
      });
    }
  }

}
