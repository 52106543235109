import { autoinject } from 'aurelia-framework';
import { MasterProductCategory, MyHttpApi } from 'utils/api';

@autoinject
export class ManifestsProductCategory {
  private list: MasterProductCategory[] = [];
  private name = "";

  constructor(private api: MyHttpApi) {
  }

  async activate() {
    await this.refresh();
  }

  async refresh() {
    this.list = await this.api.masterProductCategoryList();
  }

  async addProductCategory() {
    await this.api.masterProductCategoryUpdate(<MasterProductCategory>{ name: this.name });
    this.name = "";
    await this.refresh();
  }

  async deleteProductCategory(x: MasterProductCategory) {
    await this.api.masterProductCategoryDelete({ id: x.id });
    await this.refresh();
  }
}
