import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { MyHttpApi, NewsDTO, ReleaseLogPublicResponse, RepositoryPublicResponse } from 'utils/api';

@autoinject
export class NewsIndex {
  private dashboardList: ReleaseLogPublicResponse[] = [];
  private repositoryList: RepositoryPublicResponse[] = [];
  private newsList: NewsDTO[] = [];
  private lastAlert?: NewsDTO;
  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N) {
  }

  async activate() {
    let [dashboardList, repositoryList, newsList, lastAlert] = await Promise.all([
      this.api.releaseLogListForCustomer({limit5: true, type: "DASHBOARD"}),
      this.api.repositoryListForCustomer({limit5: true}),
      this.api.newsList({newsType: "NEWS"}),
      this.api.newsLatestAlert(),
    ]);
    this.dashboardList = dashboardList;
    this.repositoryList = repositoryList;
    this.lastAlert = lastAlert ? {
      ...lastAlert,
      shortBodyFi: this.replaceLineBreaksWithBrTag(lastAlert.shortBodyFi),
      shortBodyEn: this.replaceLineBreaksWithBrTag(lastAlert.shortBodyEn),
    } : undefined;
    this.newsList = newsList.map(news => ({
      ...news,
      shortBodyFi: this.replaceLineBreaksWithBrTag(news.shortBodyFi),
      shortBodyEn: this.replaceLineBreaksWithBrTag(news.shortBodyEn),
    }));
  }

  navigate(id:number, action:string) {
    this.router.navigateToRoute("news/"+action, { id: ""+id });
  }

  @computedFrom("i18n.i18next.language")
  get lang() {
    return this.i18n.i18next.language;
  }

  replaceLineBreaksWithBrTag(s: string) {
    return s.replace(/\n/g, '<br>');
  }
}
