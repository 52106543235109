import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { Client, Location, MyHttpApi } from 'utils/api';
import { getSelectedClient } from 'utils/corporation-util';

@autoinject
export class PosLocationList {
  private fieldList: FieldSpec[] = [
    { key: "customerName", header: "location.customerName", type: "text", },
    { key: "customerBusinessId", header: "location.customerBusinessId", type: "text", },
    { key: "name", header: "common.name", type: "text", },
    { key: "auditHour", header: "location.auditHour", type: "number", },
    { key: "areaLimited", header: "location.areaLimited", type: "boolean", },
    { key: "areaAccounting", header: "location.areaAccounting", type: "boolean", },
    { key: "doNotSendReports", header: "location.doNotSendReports", type: "boolean", },
    { key: "selfServicePinCode", header: "location.selfServicePinCode", type: "text", },
  ];
  private itemList: Location[] = [];
  private clientId?: number;

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate() {
    let clientList = await this.api.clientList();
    let clients: { [key: number]: Client; } = {};
    for (let c of clientList) {
      clients[c.id] = { ...c, name: c.nickname };
    }
    this.clientId = getSelectedClient();
    this.fieldList = [...this.fieldList,
      { key: "clientId", header: "unit.unit", type: "lookup", lookupData: clients, lookupKey: "name", },
    ];
    await this.search();
  }

  async search() {
    if (!this.clientId) {
      this.itemList = [];
      return;
    }
    const itemList = await this.api.locationList({ id: this.clientId });
    this.itemList = itemList.filter(x => !x.deleteTime);
  }

  rowCall(key: string, row?: Location) {
    this.router.navigateToRoute(this.editUrl, {id: row?.id, clientId: this.clientId });
  }

  @computedFrom("router.currentInstruction.config.navModel.config.name")
  get editUrl() {
    let routeName = this.router?.currentInstruction?.config?.navModel?.config?.name || "";
    return routeName.replace(/-list$/, "-edit");
  }

  @computedFrom("clientId")
  get searchListener() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.search();
    return "";
  }
}
