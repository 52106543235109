import { autoinject, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BaseRoute } from 'base-route';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { Client, HeralesAdminManifestListInternalRequest, HeralesManifestClientSide, IdName, ManifestError, MyHttpApi, ParamsType } from 'utils/api';
import { EventuallyCorrectSearch } from 'utils/eventually-correct-search';

@autoinject
export class ManifestsList extends BaseRoute {
  private manifestList: HeralesManifestClientSide[] = [];
  private clientList: Client[] = [];
  private clients: { [key: number]: Client } = {};
  private locationNameList: { id: string, name: string }[] = [];
  private supplierList: IdName[] = [];

  private ecs = new EventuallyCorrectSearch(
    // Lambda for passing our search params
    () => this.buildQueryParams(),
    // Lambda for searching. Passing plain function will destroy our this. and e.g. this.doPut will not work
    args => this.api.heralesAdminManifestList(args),
    // Lambda for setting data once we are done.
    data => this.applyData(data),
  );

  @observable({ changeHandler: "search" })
  private id?: string;
  @observable({ changeHandler: "search" })
  private startManifestTimestamp?: Date;
  @observable({ changeHandler: "search" })
  private endManifestTimestamp?: Date;
  @observable({ changeHandler: "search" })
  private startDeliveryDate?: Date;
  @observable({ changeHandler: "search" })
  private endDeliveryDate?: Date;
  @observable({ changeHandler: "clientChanged" })
  private clientId?: number;
  @observable({ changeHandler: "search" })
  private locationName?: string;
  @observable({ changeHandler: "search" })
  private supplierId?: number;
  @observable({ changeHandler: "search" })
  private status?: ManifestError = "NO_TRANSFER";
  @observable({ changeHandler: "search" })
  private showHidden = false;

  private fields: FieldSpec[] = [
    { header: "#", key: "id", type: "text", },
    { header: "manifest.timestamp", key: "manifestTimestamp", type: "date", format: "DD.MM.YYYY HH:MM" },
    { header: "manifest.deliveryDate", key: "deliveryDate", type: "date", format: "DD.MM.YYYY" },
    { header: "manifest.client", key: "clientId", type: "lookup", lookupData: this.clients, lookupKey: "nickname" },
    { header: "manifest.locationName", key: "locationName", type: "text" },
    { header: "manifest.supplier", key: "supplierName", type: "text" },
    { header: "manifest.status", key: "status", type: "enum", enum: "ManifestError" },
    { header: "manifest.hidden", key: "hidden", type: "boolean", },
  ];

  protected override routeParams() {
    return {
      "id": String,
      "startManifestTimestamp": Date,
      "endManifestTimestamp": Date,
      "startDeliveryDate": Date,
      "endDeliveryDate": Date,
      "clientId": Number,
      "locationName": String,
      "supplierId": Number,
      "status": String,
      "showHidden": Boolean,
    };
  }

  constructor(private api: MyHttpApi, private i18n: I18N, private router: Router) {
    super();
  }

  async activate(params: ParamsType) {
    super.activate(params);
    let [clientList, supplierList] = await Promise.all([
      this.api.clientList(),
      this.api.heralesAdminHeralesPartyList({ partyType: 'SUPPLIER' }),
      this.search(),
    ]);

    this.supplierList = supplierList.map(x => ({ id: x.partyId, name: x.name }));

    this.clientList = clientList;
    this.clientList.forEach(x => this.clients[x.id] = x);
  }

  // For base-route
  buildQueryParams(): HeralesAdminManifestListInternalRequest  {
    return {
      id: this.id,
      startManifestTimestamp: this.startManifestTimestamp,
      endManifestTimestamp: this.endManifestTimestamp,
      startDeliveryDate: this.startDeliveryDate,
      endDeliveryDate: this.endDeliveryDate,
      clientId: this.clientId,
      locationName: this.locationName,
      supplierId: this.supplierId,
      status: this.status,
      showHidden: this.showHidden,
    };
  }

  async search() {
    await this.ecs.search();
  }

  async clientChanged() {
    this.locationNameList = [];

    if(this.clientId) {
      let locationNames = await this.api.clientLocationNames({ clientId: this.clientId });
      this.locationNameList = locationNames.map(x => ({ id: x, name: x }));
    }

    await this.search();
  }

  applyData(data: HeralesManifestClientSide[]) {
    this.manifestList = data;
    super.rewriteWindowUrl(this.buildQueryParams());
  }

  async toggleHide(row: HeralesManifestClientSide) {
    await this.api.heralesAdminToggleHide({ id: row.id });
    await this.search();
  }

  rowCall(key: string, row: HeralesManifestClientSide) {
    if (row.id) {
      this.router.navigateToRoute("manifests/edit", { id: "" + row.id });
    }
  }
}
