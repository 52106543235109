import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Client, HeralesParty, MyHttpApi, PartyType } from 'utils/api';

@autoinject
export class ManifestsPartyLink {
  private party?: HeralesParty;
  private masterId?: number;

  private clientList: Client[] = [];
  private clientId?: number;
  private locationName = "";

  private posName = "";

  constructor(private router: Router, private api: MyHttpApi) {
  }

  async activate(params: { partyId: string, partyType: PartyType }) {
    let partyId = parseInt(params.partyId);

    let partyList = await this.api.heralesAdminHeralesPartyList({ partyType: params.partyType });
    this.party = partyList.find(p => p.partyId == partyId);
    if (!this.party) {
      return;
    }

    if (params.partyType == "RECIPIENT") {
      /* Find pre-existing clientLocation info if we have it */
      let clientLocation = await this.api.heralesAdminHeralesPartyClientLocation({ partyId });
      if (clientLocation) {
        this.masterId = clientLocation.id;
        this.clientId = clientLocation.clientId;
        this.locationName = clientLocation.locationName;
      }
      this.clientList = await this.api.clientList();
    }

    if (params.partyType == "SUPPLIER") {
      /* Find pre-existing supplier info if we have it */
      let partySupplier = await this.api.heralesAdminHeralesPartySupplier({ partyId });
      if (partySupplier) {
        this.masterId = partySupplier.id;
        this.posName = partySupplier.name;
      } else {
        this.posName = this.party.name;
      }
    }
  }

  async saveRecipient() {
    if (!this.party || !this.clientId) {
      return;
    }
    await this.api.heralesAdminHeralesPartyToClientLocation({
      partyId: this.party.partyId,
      clientId: this.clientId,
      locationName: this.locationName,
    });
    this.router.navigateBack();
  }

  async saveSupplier() {
    if (!this.party) {
      return;
    }
    await this.api.heralesAdminHeralesPartyToSupplier({
      partyId: this.party.partyId,
      name: this.posName,
    });
    this.router.navigateBack();
  }
}
