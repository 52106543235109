import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { MyHttpApi, NewsDTO } from 'utils/api';

@autoinject
export class NewsNewsArticle {
  private news?: NewsDTO;
  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N) {
  }

  async activate(params: { id: string; }) {
    this.news = await this.api.newsById({ id: parseInt(params.id), asUri: false });
  }

  @computedFrom("i18n.i18next.language")
  get lang() {
    return this.i18n.i18next.language;
  }
}
