import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { Country, MasterProduct, MyHttpApi } from 'utils/api';

@autoinject
export class ManifestsProductList {
  private list: MasterProduct[] = [];
  private fields: FieldSpec[] = [];
  private countries: { [key: string]: Country } = {};
  constructor(private i18n: I18N, private api: MyHttpApi, private router: Router) {
  }

  async activate() {
    let lang = this.i18n.getLocale() || "fi";
    let [productCategoryList, countries] = await Promise.all([
      this.api.masterProductCategoryList(),
      this.api.countryList(),
    ]);

    countries.forEach(x => {
      this.countries[x.cc3] =  { ...x };
    });
    this.fields = [
      { key: "id", header: "#", type: "text", },
      { key: "name", header: "masterProduct.name", type: "text", },
      { key: "baseunit", header: "masterProduct.baseUnit", type: "enum", enum: "BaseUnit", },
      { key: "originCountry", header: "masterProduct.originCountry", type: "lookup", lookupData: this.countries, lookupKey: lang },
      { key: "productCategoryId", header: "masterProduct.productCategory", type: "lookup", lookupData: MyHttpApi.toHash(productCategoryList), lookupKey: "name", },
      { key: "tags", header: "masterProduct.tags", type: "text" },
      { key: "sendToPos", header: "masterProduct.sendToPos", type: "boolean" },
    ];
    this.refresh();
  }

  async refresh() {
    this.list = await this.api.masterProductList();
  }
  rowCall(key: string, row: MasterProduct) {
    if (row.id) {
      this.router.navigateToRoute("manifests/product-edit", {id: ""+ row.id});
    }
  }
}
