import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { MyHttpApi, RepositoryPublicResponse } from 'utils/api';

@autoinject
export class NewsPosUpdate {
  private content: RepositoryPublicResponse[] = [];
  private perPage = 20;
  private page = 1;
  private selectedBranch?: string;
  private branches: { id: string, name: string; }[] = [];
  private filteredContent: RepositoryPublicResponse[] = [];

  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N) {
  }

  async activate(params: { id: string; }) {
    let [content, branchList] = await Promise.all([
      this.api.repositoryListForCustomer({ limit5: false }),
      this.api.repositoryListBranches()
    ]);
    this.content = content;
    this.branches = branchList.map(x => {
      return {
        id: x,
        name: x
      };
    });
    if (params.id) {
      let branch = this.content.find(b => b.id == parseInt(params.id));
      this.selectedBranch = this.branches.find(b => b.id === branch?.branch)?.id;
    }
  }

  @computedFrom("filteredContent", "perPage")
  get maxPage() {
    if (!this.content) {
      return 1;
    }
    return Math.floor((this.filteredContent.length - 1) / this.perPage) + 1;
  }

  @computedFrom("page", "maxPage")
  get paging() {
    const array: number[] = [];
    for (let i = Math.max(1, this.page - 5); i <= Math.min(this.maxPage, this.page + 5); i++) {
      array.push(i);
    }
    return array;
  }

  setPage(page: number) {
    this.page = Math.max(1, Math.min(page, this.maxPage));
  }

  @computedFrom("content", "page", "perPage", "selectedBranch")
  get rows() {
    let content = this.content;
    if (this.selectedBranch) {
      content = content.filter(x => this.selectedBranch === x.branch);
    }
    this.filteredContent = content.slice((this.page - 1) * this.perPage, this.page * this.perPage);
    return this.filteredContent;
  }

  @computedFrom("i18n.i18next.language")
  get lang() {
    return this.i18n.i18next.language;
  }
}
