// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form.product-edit div.storageUnit {
  position: relative;
  border: 1px solid #888;
  padding: 4px;
  margin-bottom: 1em;
}
form.product-edit div.storageUnit button.storageUnitDelete {
  position: absolute;
  top: 0px;
  right: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/manifests/product-edit.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;AACJ;AALA;EAOQ,kBAAA;EACA,QAAA;EACA,UAAA;AACR","sourcesContent":["form.product-edit div.storageUnit {\n    position: relative;\n    border: 1px solid #888;\n    padding: 4px;\n    margin-bottom: 1em;\n\n    button.storageUnitDelete {\n        position: absolute;\n        top: 0px;\n        right: 0px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
