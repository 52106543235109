import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { HeralesSupplierProduct, MasterProductByIdResult, MasterProductByIdStorageUnitResult, MasterProductCategory, MasterProductStorageUnitEan, MyHttpApi, PosBaseUnit } from 'utils/api';

@autoinject
export class ManifestsProductEdit {
  private baseUnitList: { id: PosBaseUnit, name: string }[] = [
    { id: "PCS", name: this.i18n.tr("BaseUnit.PCS") },
    { id: "KG", name: this.i18n.tr("BaseUnit.KG") },
    { id: "L", name: this.i18n.tr("BaseUnit.L") },
  ];
  private productCategoryList: MasterProductCategory[] = [];
  private product: MasterProductByIdResult = {
    product: {
      id: 0,
      name: "",
      baseUnit: "PCS",
      productCategoryId: 0,
      originCountry: "FIN",
      tags: [],
      sendToPos: true,
    },
    storageUnits: [],
  };
  private tags = "";

  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N) {
  }

  async activate(params: { id: string }) {
    this.productCategoryList = await this.api.masterProductCategoryList();
    if (params.id) {
      this.product = await this.api.masterProductById({ id: parseInt(params.id) });
      this.tags = this.product.product.tags.join(", ");
    }
  }

  async save() {
    this.product.product.tags = this.tags.split(/,\s*/);
    await this.api.masterProductUpdate(this.product);
    this.router.navigateBack();
  }

  addSu() {
    this.product.storageUnits.push({
      storageUnit: {
        id: 0,
        masterProductId: 0,
        name: this.product.product.name,
        storageUnit: 0,
      },
      supplierProducts: [],
      eans: [],
    });
  }
  deleteSu(su: MasterProductByIdStorageUnitResult) {
    this.product.storageUnits = this.product.storageUnits.filter(p => p != su);
  }

  async deleteSupplierProductLink(su: MasterProductByIdStorageUnitResult, x: HeralesSupplierProduct) {
    await this.api.heralesAdminSupplierProductLink({
      partyId: x.partyId,
      productCode: x.productCode,
    });
    su.supplierProducts = su.supplierProducts.filter(p => p != x);
  }

  addEan(su: MasterProductByIdStorageUnitResult) {
    su.eans.push({
      storageUnitId: 0,
      id: 0,
      ean: "",
      name: su.storageUnit.name,
    })
  }
  deleteEan(su: MasterProductByIdStorageUnitResult, ean: MasterProductStorageUnitEan) {
    su.eans = su.eans.filter(p => p != ean);
  }
}
