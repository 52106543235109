import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { MyHttpApi, ReleaseLogPublicResponse } from 'utils/api';

@autoinject
export class NewsDashboardUpdate {
  private content: ReleaseLogPublicResponse[] = [];
  private perPage = 20;
  private page = 1;

  constructor(private api: MyHttpApi, private i18n: I18N) {
  }

  async activate() {
    this.content = await this.api.releaseLogListForCustomer({ limit5: false, type: "DASHBOARD" });
  }

  @computedFrom("content", "perPage")
  get maxPage() {
    return Math.max(1, Math.floor((this.content.length - 1) / this.perPage) + 1);
  }

  @computedFrom("page", "maxPage")
  get paging() {
    const array: number[] = [];
    for (let i = Math.max(1, this.page - 5); i <= Math.min(this.maxPage, this.page + 5); i++) {
      array.push(i);
    }
    return array;
  }

  setPage(page: number) {
    this.page = Math.max(1, Math.min(page, this.maxPage));
  }

  @computedFrom("content", "page", "perPage")
  get rows() {
    return this.content.slice((this.page - 1) * this.perPage, this.page * this.perPage);
  }

  @computedFrom("i18n.i18next.language")
  get lang() {
    return this.i18n.i18next.language;
  }
}
